import { CoreMessage } from "./index";

export const MessageList = (props) => {
  const { messages } = props;
  return (
    <div className="flex flex-1 flex-col p-4 overflow-y-auto">
      {messages.map((message, index) => {
        return <CoreMessage message={message} key={index} />;
      })}
    </div>
  );
};

export default MessageList;
