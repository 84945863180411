import linkedInImage from "../files/linkedin.svg";
import githubImage from "../files/github.svg";
import emailImage from "../files/envelope-at-fill.svg";
import resumeImage from "../files/file-text-fill.svg";
import resumeFile from "../files/nick-delfino-resume.pdf";

export const DefaultMessage = () => {
  return (
    <div className="">
      <p className="mx-2">
        Hey! This is a chatbot which responds with context from my resume. Some
        of the results may not be 100% accurate or contain all the necessary
        information. To get more info: reach out to me on LinkedIn, look at my
        Github, send me an email, or look at my resume with the links below.
      </p>
      <div className="flex justify-center items-center pt-2">
        <a
          href="https://www.linkedin.com/in/nick-delfino-7044b878/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={linkedInImage}
            alt="LinkedIn Icon"
            className="p-0.5 w-12 h-12 fill-red-600 "
          />
        </a>
        <a
          href="https://github.com/NickDelfino"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={githubImage}
            alt="Github Icon"
            className="p-0.5 w-12 h-12"
          />
        </a>
        <a
          href="mailto:delfinonick+recruit@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={emailImage} alt="Email Icon" className="p-0.5 w-12 h-12" />
        </a>
        <a href={resumeFile} target="_blank" rel="noopener noreferrer">
          <img
            src={resumeImage}
            alt="Resume Icon"
            className="p-0.5 w-12 h-12"
          />
        </a>
      </div>
    </div>
  );
};

export default DefaultMessage;
