const NavBar = () => {
  return (
    <div className="bg-neutral-900 text-white p-4">
      <div className="flex flex-1 justify-center">
        <h2 className="text-2xl">Nick GPT</h2>
      </div>
    </div>
  );
};

export default NavBar;
