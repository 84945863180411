import "./App.css";
import { useState } from "react";
import {
  NavBar,
  MessageList,
  DefaultMessage,
  LoadingMessage,
} from "./components";

function App() {
  const [messages, setMessages] = useState([
    {
      text: <DefaultMessage />,
      sender: "bot",
    },
  ]);

  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSendMessage = () => {
    if (!userInput.trim()) return; // Ignore empty messages

    // Add user message to chat
    const newMessages = [
      ...messages,
      { text: userInput, sender: "user" },
      { text: <LoadingMessage />, sender: "bot" },
    ];
    setMessages(newMessages);
    setLoading(true);
    setUserInput("");

    fetch("https://nick-resume-chatbot.looking-for-group.workers.dev", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: userInput,
      }),
    }).then((response) => {
      if (!response.ok) {
        setMessages([
          ...newMessages.slice(0, -1),
          {
            text: "The request failed. This could be due to rate limiting.",
            sender: "bot",
          },
        ]);
      }

      response.json().then((j) => {
        setMessages([
          ...newMessages.slice(0, -1),
          { text: `${j.response}`, sender: "bot" },
        ]);
      });

      setLoading(false);
    });
  };

  return (
    <div className="flex flex-col h-dvh bg-neutral-800">
      <NavBar />
      <MessageList messages={messages} loading={loading} />
      <div className="flex items-center border-2 border-gray-300 rounded-lg bg-neutral-800">
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
          className="w-full p-2 rounded-lg focus:outline-none bg-neutral-800 placeholder-neutral-400 text-white"
          placeholder="What is his current job?"
          disabled={loading}
          autoFocus={true}
        />
        <button className="svg-button" onClick={() => handleSendMessage()}>
          <div className="fill-neutral-300 hover:fill-neutral-400 active:fill-neutral-500 mr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              className="bi bi-arrow-up-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z" />
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
}

export default App;
