import ReactMarkdown from "react-markdown";

export const CoreMessage = (props) => {
  const { message } = props;

  let text = message.text;

  const lol =
    typeof text === "string" ? <ReactMarkdown>{text}</ReactMarkdown> : text;

  return (
    <div
      className={`flex ${message.sender === "user" ? "justify-end" : "justify-start"} p-1`}
    >
      <div
        className={`max-w-xs md:max-w-md lg:max-w-lg p-3 rounded-lg 
                            ${
                              message.sender === "user"
                                ? "bg-blue-500 text-white"
                                : "bg-neutral-700 text-white"
                            } 
                                relative`}
      >
        {lol}
        <div
          className={`absolute w-3 h-3 bg-transparent border-8 mt-[-15px] 
                                ${
                                  message.sender === "user"
                                    ? "border-blue-500 right-1 mr-[-11px]"
                                    : "border-neutral-700 left-1 ml-[-11px]"
                                } 
                                transform rotate-45`}
        />
      </div>
    </div>
  );
};

export default CoreMessage;
